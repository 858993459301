exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-buisness-about-meeeningful-js": () => import("./../../../src/pages/buisnessAboutMeeeningful.js" /* webpackChunkName: "component---src-pages-buisness-about-meeeningful-js" */),
  "component---src-pages-buisness-web-commision-js": () => import("./../../../src/pages/buisnessWebCommision.js" /* webpackChunkName: "component---src-pages-buisness-web-commision-js" */),
  "component---src-pages-buisness-web-service-js": () => import("./../../../src/pages/buisnessWebService.js" /* webpackChunkName: "component---src-pages-buisness-web-service-js" */),
  "component---src-pages-coming-soon-js": () => import("./../../../src/pages/comingSoon.js" /* webpackChunkName: "component---src-pages-coming-soon-js" */),
  "component---src-pages-cookies-policy-js": () => import("./../../../src/pages/cookiesPolicy.js" /* webpackChunkName: "component---src-pages-cookies-policy-js" */),
  "component---src-pages-feedback-js": () => import("./../../../src/pages/feedback.js" /* webpackChunkName: "component---src-pages-feedback-js" */),
  "component---src-pages-gallery-js": () => import("./../../../src/pages/gallery.js" /* webpackChunkName: "component---src-pages-gallery-js" */),
  "component---src-pages-games-js": () => import("./../../../src/pages/games.js" /* webpackChunkName: "component---src-pages-games-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-platformer-js": () => import("./../../../src/pages/platformer.js" /* webpackChunkName: "component---src-pages-platformer-js" */),
  "component---src-pages-privacy-js": () => import("./../../../src/pages/privacy.js" /* webpackChunkName: "component---src-pages-privacy-js" */),
  "component---src-pages-security-js": () => import("./../../../src/pages/security.js" /* webpackChunkName: "component---src-pages-security-js" */),
  "component---src-pages-terms-and-conditions-js": () => import("./../../../src/pages/termsAndConditions.js" /* webpackChunkName: "component---src-pages-terms-and-conditions-js" */),
  "component---src-pages-what-is-new-js": () => import("./../../../src/pages/whatIsNew.js" /* webpackChunkName: "component---src-pages-what-is-new-js" */)
}

